import MuiZoomIn from '@mui/icons-material/ZoomIn';
import MuiAddPhotoAlternate from '@mui/icons-material/AddPhotoAlternate';
import MuiDeleteForever from '@mui/icons-material/DeleteForever';
import MuiQuestionAnswer from '@mui/icons-material/QuestionAnswer';
import MuiAccessTimeFilled from '@mui/icons-material/AccessTimeFilled';
import MuiCheckCircle from '@mui/icons-material/CheckCircle';
import MuiQuickreply from '@mui/icons-material/Quickreply';
import MuiSavings from '@mui/icons-material/Savings';
import MuiChevronLeft from '@mui/icons-material/ChevronLeft';
import MuiChevronRight from '@mui/icons-material/ChevronRight';
import MuiArrowBack from '@mui/icons-material/ArrowBack';
import MuiArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import MuiCall from '@mui/icons-material/Call';
import MuiCleaningServicesOutlined from '@mui/icons-material/CleaningServicesOutlined';
import MuiConstructionOutlined from '@mui/icons-material/ConstructionOutlined';
import MuiFormatPaintOutlined from '@mui/icons-material/FormatPaintOutlined';
import MuiHandymanOutlined from '@mui/icons-material/HandymanOutlined';
import MuiHomeRepairServiceOutlined from '@mui/icons-material/HomeRepairServiceOutlined';
import MuiMenu from '@mui/icons-material/Menu';
import MuiOpenInNew from '@mui/icons-material/OpenInNew';
import MuiPlumbingOutlined from '@mui/icons-material/PlumbingOutlined';
import MuiSend from '@mui/icons-material/Send';
import MuiAccountCircle from '@mui/icons-material/AccountCircle';
import MuiExpandMore from '@mui/icons-material/ExpandMore';
import MuiArrowBackIos from '@mui/icons-material/ArrowBackIos';
import MuiHelp from '@mui/icons-material/Help';
import MuiPriorityHigh from '@mui/icons-material/PriorityHigh';
import MuiLocationOnOutlined from '@mui/icons-material/LocationOnOutlined';
import MuiRefresh from '@mui/icons-material/Refresh';
import MuiWarning from '@mui/icons-material/Warning';
import MuiMap from '@mui/icons-material/Map';
import MuiCheck from '@mui/icons-material/Check';
import MuiClose from '@mui/icons-material/Close';
import MuiMoreVert from '@mui/icons-material/MoreVert';
import MuiImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import MuiHideImageOutlinedIcon from '@mui/icons-material/HideImageOutlined';
import MuiCopyIcon from '@mui/icons-material/ContentCopy';
import MuiEditIcon from '@mui/icons-material/Edit';

export const ZoomIn = MuiZoomIn;
export const AddPhotoAlternate = MuiAddPhotoAlternate;
export const DeleteForever = MuiDeleteForever;
export const QuestionAnswer = MuiQuestionAnswer;
export const AccessTimeFilled = MuiAccessTimeFilled;
export const CheckCircle = MuiCheckCircle;
export const Quickreply = MuiQuickreply;
export const Savings = MuiSavings;
export const ChevronLeft = MuiChevronLeft;
export const ChevronRight = MuiChevronRight;
export const ArrowBack = MuiArrowBack;
export const ArrowForwardIos = MuiArrowForwardIos;
export const Call = MuiCall;
export const CleaningServicesOutlined = MuiCleaningServicesOutlined;
export const Close = MuiClose;
export const ConstructionOutlined = MuiConstructionOutlined;
export const FormatPaintOutlined = MuiFormatPaintOutlined;
export const HandymanOutlined = MuiHandymanOutlined;
export const HomeRepairServiceOutlined = MuiHomeRepairServiceOutlined;
export const Menu = MuiMenu;
export const OpenInNew = MuiOpenInNew;
export const PlumbingOutlined = MuiPlumbingOutlined;
export const Send = MuiSend;
export const AccountCircle = MuiAccountCircle;
export const ExpandMore = MuiExpandMore;
export const ArrowBackIos = MuiArrowBackIos;
export const Help = MuiHelp;
export const PriorityHigh = MuiPriorityHigh;
export const LocationOnOutlined = MuiLocationOnOutlined;
export const Refresh = MuiRefresh;
export const Warning = MuiWarning;
export const Map = MuiMap;
export const Check = MuiCheck;
export const MoreVert = MuiMoreVert;
export const ImageOutlinedIcon = MuiImageOutlinedIcon;
export const HideImageOutlinedIcon = MuiHideImageOutlinedIcon;
export const Copy = MuiCopyIcon;
export const Edit = MuiEditIcon;
