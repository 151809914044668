import { CircularProgress } from '@repo/mui/CircularProgress';
import { Stack } from '@repo/mui/Stack';

export const LoadingCircular = () => {
  return (
    <Stack width='100%' alignItems='center' padding={2}>
      <CircularProgress />
    </Stack>
  );
};
