import { useSession } from '@repo/next-auth/client';
import { User } from '../auth.ts';
import { setGlobalContextProperty } from '@repo/client-logger/datadog/context';

export const DatadogContextSetter = () => {
  const session = useSession();

  const user = session?.data?.user as User | undefined;
  const id = user?.id;
  const phone = user?.phone;

  setGlobalContextProperty('userId', id);
  setGlobalContextProperty('userPhone', phone);

  return null;
};
