import { init } from '@repo/client-logger/datadog/init';
import { useEffect } from 'react';
import {
  NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
  NEXT_PUBLIC_DATADOG_ENV,
  NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID,
  NEXT_PUBLIC_DATADOG_RUM_ENABLED,
  NEXT_PUBLIC_COMMIT_SHA,
} from '../env.ts';

let initialized = false;

export const DatadogInit = () => {
  useEffect(() => {
    if (initialized) {
      return;
    }
    const clientToken = NEXT_PUBLIC_DATADOG_CLIENT_TOKEN;
    const env = NEXT_PUBLIC_DATADOG_ENV;
    const rumApplicationId = NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID;
    const rumEnabled = NEXT_PUBLIC_DATADOG_RUM_ENABLED === '1';

    init({
      clientToken,
      env,
      service: 'worker-app',
      rumApplicationId,
      rumEnabled,
      version: NEXT_PUBLIC_COMMIT_SHA,
      trackUserInteractions: true,
    });
    initialized = true;
  }, []);

  return null;
};
